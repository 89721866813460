import Img1 from "../../images/Gallery/GalleryMax1200px/1-min.jpg";
import Img2 from "../../images/Gallery/GalleryMax1200px/2-min.jpg";
import Img3 from "../../images/Gallery/GalleryMax1200px/3-min.jpg";
import Img4 from "../../images/Gallery/GalleryMax1200px/4-min.jpg";
import Img5 from "../../images/Gallery/GalleryMax1200px/5-min.jpg";
import Img6 from "../../images/Gallery/GalleryMax1200px/6-min.jpg";
import Img7 from "../../images/Gallery/GalleryMax1200px/7-min.jpg";
import Img8 from "../../images/Gallery/GalleryMax1200px/8-min.jpg";
import Img9 from "../../images/Gallery/GalleryMax1200px/9-min.jpg";
import Img10 from "../../images/Gallery/GalleryMax1200px/10-min.jpg";
import Img11 from "../../images/Gallery/GalleryMax1200px/11-min.jpg";
import Img12 from "../../images/Gallery/GalleryMax1200px/12-min.jpg";
import Img13 from "../../images/Gallery/GalleryMax1200px/13-min.jpg";
import Img14 from "../../images/Gallery/GalleryMax1200px/14-min.jpg";
import Img15 from "../../images/Gallery/GalleryMax1200px/15-min.jpg";
import Img16 from "../../images/Gallery/GalleryMax1200px/16-min.jpg";
import Img17 from "../../images/Gallery/GalleryMax1200px/17-min.jpg";
import Img18 from "../../images/Gallery/GalleryMax1200px/18-min.jpg";
import Img19 from "../../images/Gallery/GalleryMax1200px/19-min.jpg";
import Img20 from "../../images/Gallery/GalleryMax1200px/20-min.jpg";
import Img21 from "../../images/Gallery/GalleryMax1200px/21-min.jpg";
import Img22 from "../../images/Gallery/GalleryMax1200px/22-min.jpg";
import Img23 from "../../images/Gallery/GalleryMax1200px/23-min.jpg";
import Img24 from "../../images/Gallery/GalleryMax1200px/24.jpg";

export const photos = [
  { src: Img1, width: 3, height: 3, loading: "lazy" },
  { src: Img2, width: 2.4, height: 3, loading: "lazy" },
  { src: Img3, width: 2.4, height: 3, loading: "lazy" },
  { src: Img4, width: 2.4, height: 3, loading: "lazy" },
  { src: Img5, width: 3, height: 3, loading: "lazy" },
  { src: Img6, width: 2, height: 3, loading: "lazy" },
  { src: Img7, width: 2, height: 3, loading: "lazy" },
  { src: Img8, width: 3, height: 4, loading: "lazy" },
  { src: Img9, width: 2, height: 3, loading: "lazy" },
  { src: Img10, width: 2, height: 3, loading: "lazy" },
  { src: Img11, width: 4, height: 3, loading: "lazy" },
  { src: Img12, width: 2, height: 3, loading: "lazy" },
  { src: Img13, width: 2, height: 3, loading: "lazy" },
  { src: Img14, width: 5, height: 3, loading: "lazy" },
  { src: Img15, width: 2, height: 3, loading: "lazy" },
  { src: Img16, width: 2, height: 3, loading: "lazy" },
  { src: Img17, width: 2.5, height: 3, loading: "lazy" },
  { src: Img18, width: 2.5, height: 3, loading: "lazy" },
  { src: Img19, width: 2.5, height: 3, loading: "lazy" },
  { src: Img20, width: 2, height: 3, loading: "lazy" },
  { src: Img21, width: 2, height: 3, loading: "lazy" },
  { src: Img22, width: 2, height: 3, loading: "lazy" },
  { src: Img23, width: 2, height: 3, loading: "lazy" },
  { src: Img24, width: 3, height: 2, loading: "lazy" }
];