// @flow

import React, { useState, useCallback } from 'react';
import './gallery.scss';
import Gal from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";

function Gallery() {

	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const navButtonStyles = base => ({
		...base,
		'&:hover, &:active': {
		},
		'&:active': {
		  outline: 'none',
		}
	  });

	return (
		<div className="gallery">
			<Gal photos={photos} lazyload={true} onClick={openLightbox} />
			<ModalGateway>
				{viewerIsOpen ? (
				<Modal onClose={closeLightbox}>
					<Carousel
						currentIndex={currentImage}
						views={photos.map(x => ({
							...x,
							srcset: x.srcset,
							caption: x.title
						}))}
						styles={{
							container: base => ({
								...base,
							}),
							view: base => ({
								...base,
							}),
							navigationPrev: navButtonStyles,
							navigationNext: navButtonStyles,
						}}
					/>
				</Modal>
			) : null}
			</ModalGateway>
		</div>
	);
}

export { Gallery };
