import Img1 from "../../images/Resume/2022-12/1.jpg";
import Img2 from "../../images/Resume/2022-12/2.jpg";
import Img3 from "../../images/Resume/2022-12/3.jpg";
import Img4 from "../../images/Resume/2022-12/4.jpg";
import Img5 from "../../images/Resume/2022-12/5.jpg";
import Img6 from "../../images/Resume/2022-12/6.jpg";
import Img7 from "../../images/Resume/2022-12/7.jpg";
import Img8 from "../../images/Resume/2022-12/8.jpg";
import Img9 from "../../images/Resume/2022-12/9.jpg";
import Img10 from "../../images/Resume/2022-12/10.jpg";
import Img11 from "../../images/Resume/2022-12/11.jpg";
import Img12 from "../../images/Resume/2022-12/12.jpg";


export const headshots = [
  { src: Img1, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img2, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img3, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img4, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img5, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img6, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img7, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img8, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img9, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img10, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img11, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img12, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
];