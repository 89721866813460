// @flow

import React, { useCallback, useState } from 'react';
import './headshots.scss';
import Gal from 'react-photo-gallery';
import Carousel, {Modal, ModalGateway } from 'react-images';
import { headshots } from './photos';

function Headshots() {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const navButtonStyles = base => ({
		...base,
		'&:hover, &:active': {
		},
		'&:active': {
		  outline: 'none',
		},
	});

	const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	let columns;
	if (vw >= 1200) {
		columns = 4
	} else if (vw >= 1200) {
		columns = 2
	}

		return (
			<div className="headshots-grid">
				<div className="headshots">
					<Gal photos={headshots} onClick={openLightbox} direction={'column'} columns={columns}/>
					<ModalGateway>
						{viewerIsOpen ? (
						<Modal onClose={closeLightbox}>
							<Carousel
								views={headshots} 
								currentIndex={currentImage}	
								styles={{
									container: base => ({
										...base,
									}),
									view: base => ({
										...base,
									}),
									navigationPrev: navButtonStyles,
									navigationNext: navButtonStyles,
								}}
								/>
						</Modal>
					) : null}
					</ModalGateway>
				</div>
			</div>
		);
	}

export { Headshots };
