// @flow

import React, { Component } from 'react';
import './videos.scss';
import ModalVideo from 'react-modal-video';

import t1 from '../../images/Videos/NewThumbs/Drama2.jpg';
import t2 from '../../images/Videos/NewThumbs/Comedy2.jpg';
import t3 from '../../images/Videos/NewThumbs/Combat.jpg';
import t4 from '../../images/Videos/NewThumbs/Russian.jpg';
import t5 from "../../images/Videos/NewThumbs/Tennis.jpg";
import t6 from "../../images/Videos/NewThumbs/murdock.png";
import t7 from "../../images/Videos/NewThumbs/Yoga.jpg";
import t8 from "../../images/Videos/WindsongCropped.jpg";

import mobile1 from '../../images/Videos/MobileThumbs/Drama.webp';
import mobile2 from '../../images/Videos/MobileThumbs/Comedy2.webp';
import mobile3 from '../../images/Videos/MobileThumbs/Russian2.webp';
import mobile4 from '../../images/Videos/MobileThumbs/Combat2.webp';

import murdock from "../../images/Videos/Murdock-Fits-Your-Lifestyle-1.mp4";

class Videos extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			isOpen2: false,
			isOpen3: false,
			isOpen4: false,
			isOpen5: false,
    };
		this.openModal = this.openModal.bind(this);
	}

	openModal() {
		this.setState({ isOpen: true });
	}

	render() {
		return (
      <div className="reels">
        <span className="section-title">Reels</span>
        <hr></hr>
        <div className="video-grid">
          <div className="video1">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen}
              videoId="746739503"
              onClose={() => this.setState({ isOpen: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen: true })}
              >
                DRAMA <br /> REEL
              </span>
              <img
                className="thumb"
                src={t1}
                alt=""
                title="Watch Drama Reel"
                onClick={() => this.setState({ isOpen: true })}
              />
              <img
                className="mobile"
                src={mobile1}
                alt=""
                onClick={() => this.setState({ isOpen: true })}
              />
            </div>
          </div>
          <div className="video2">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen2}
              videoId="746737890"
              onClose={() => this.setState({ isOpen2: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen2: true })}
              >
                COMEDY <br /> REEL
              </span>
              <img
                className="thumb"
                src={t2}
                alt=""
                title="Watch Comedy Reel"
                onClick={() => this.setState({ isOpen2: true })}
              />
              <img
                className="mobile"
                src={mobile2}
                alt=""
                onClick={() => this.setState({ isOpen2: true })}
              />
            </div>
          </div>
          <div className="video4">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen4}
              videoId="517411504"
              allow="autoplay"
              onClose={() => this.setState({ isOpen4: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen4: true })}
              >
                COMBAT <br /> REEL
              </span>
              <img
                className="thumb"
                src={t3}
                alt=""
                title="Watch Combat Reel"
                onClick={() => this.setState({ isOpen4: true })}
              />
              <img
                className="mobile"
                src={mobile4}
                alt=""
                onClick={() => this.setState({ isOpen4: true })}
              />
            </div>
          </div>
          <div className="video3">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen3}
              videoId="517413040"
              onClose={() => this.setState({ isOpen3: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen3: true })}
              >
                RUSSIAN <br /> REEL
              </span>
              <img
                className="thumb"
                src={t4}
                alt=""
                title="Watch Russian Reel"
                onClick={() => this.setState({ isOpen3: true })}
              />
              <img
                className="mobile"
                src={mobile3}
                alt=""
                onClick={() => this.setState({ isOpen3: true })}
              />
            </div>
          </div>
          <div className="video5">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen5}
              videoId="863441820"
              onClose={() => this.setState({ isOpen5: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen5: true })}
              >
                TENNIS <br /> REEL
              </span>
              <img
                className="thumb"
                src={t5}
                alt=""
                title="Watch Tennis Reel"
                onClick={() => this.setState({ isOpen5: true })}
              />
              <img
                className="mobile"
                src={t5}
                alt=""
                onClick={() => this.setState({ isOpen5: true })}
              />
            </div>
          </div>
          <div className="video6">
            <ModalVideo
              className="modal-video"
              channel="vimeo"
              isOpen={this.state.isOpen6}
              videoId="940947349"
              onClose={() => this.setState({ isOpen6: false })}
            />
            <div className="videoOverlay">
              <span
                className="caption"
                onClick={() => this.setState({ isOpen6: true })}
              >
                YOGA <br /> REEL
              </span>
              <img
                className="thumb"
                src={t7}
                alt=""
                title="Watch Yoga Reel"
                onClick={() => this.setState({ isOpen6: true })}
              />
              <img
                className="mobile"
                src={t7}
                alt=""
                onClick={() => this.setState({ isOpen6: true })}
              />
            </div>
          </div>
        </div>
        <span className="section-title">Most Recent Work</span>
        <hr></hr>
        <div className="video-grid">
          <div className="video2" >
            <div className="videoOverlay">
              <img className="thumb" src={t8} alt="" title="Reverse: 1999" />
              <img className="mobile" src={t8} alt="" />
            </div>
          </div>
          <div className="video1">
            <ModalVideo
              className="modal-video"
              channel="custom"
              url={murdock}
              isOpen={this.state.isOpen}
              onClose={() => this.setState({ isOpen: false })}
            />
            <div className="videoOverlay">
              <img
                className="thumb"
                src={t6}
                alt=""
                title="Watch Murdock Commercial"
                onClick={() => this.setState({ isOpen: true })}
              />
              <img
                className="mobile"
                src={t6}
                alt=""
                onClick={() => this.setState({ isOpen: true })}
              />
            </div>
          </div>
        </div>
      </div>
    );
	}
}

export default Videos;
