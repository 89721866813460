// @flow

import React, { Component } from 'react';
import './App.scss';
import Layout from './components/Layout/layout';

export default class App extends Component {
	render() {
		return <Layout />;
	}
}
