// @flow

import React, { Component } from "react";
import "./contact.scss";
import LizContact from "../../images/Contact/41resize.jpg";

class Contact extends Component {
  state = {
    value: "",
    copied: false,
  };

  render() {
    return (
      <div className="grid-contact">
        <div className="contact-info">
          <div className="personal-info">
            <div className="agency-wrapper">
              <div className="management">
                <span className="type">Management</span>: Lucia Chang <br />
                lucia@sidecarmanagement.com <br />
                Office: 323-286-4022
              </div>

              <div className="commercial">
                <span className="type">Commercial</span>: Reign Agency <br />
                Laura Soo Hoo <br />
                assistant@reignagency.com
              </div>
            </div>
            {/* <h5 className="personal-header">Contact Me</h5> */}
            <ul>
              <li>
                <a
                  className="contact-email"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:yelsorkin@gmail.com"
                >
                  yelsorkin@gmail.com
                </a>
              </li>
              <li>
                <a
                  className="imdb"
                  href="https://www.imdb.com/name/nm5608636/"
                  target=" _blank"
                  rel="noopener noreferrer"
                >
                  <span>IMDb</span>
                </a>
              </li>
              <li>
                <a
                  className="actors"
                  href="https://resumes.actorsaccess.com/yelizavetasorkin"
                  target=" _blank"
                  rel="noopener noreferrer"
                >
                  <span>Actors Access</span>
                </a>
              </li>
              <li>
                <a
                  className="lacasting"
                  href="https://app.castingnetworks.com/talent/public-profile/854a089c-9159-11ea-9bfa-0291f623b406"
                  target=" _blank"
                  rel="noopener noreferrer"
                >
                  <span>LA Casting</span>
                </a>
              </li>
              <li>
                <a
                  className="insta"
                  href="https://www.instagram.com/yelsorkin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-imgDiv">
          <div className="contact-img">
            <img className="img" src={LizContact} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
