// @flow

import React, { Component } from 'react';
import './layout.scss';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import Home from '../Home/home';
import { Gallery } from '../Gallery/gallery';
import Videos from '../Videos/videos';
import { Headshots } from '../Headshots/headshots';
import Contact from '../Contact/contact';
import FourOhFour from './FourOhFour';

class Layout extends Component {
	render() {
		return (
      <div className="content">
        <div className="mobileMenu right">
          <Menu
            slide
            right
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          >
            <a
              id="Gallery"
              className="mobile-menu-item"
              tabIndex="0"
              href="/gallery"
            >
              Gallery
            </a>
            <a
              id="Reels"
              className="mobile-menu-item"
              tabIndex="0"
              href="/reels"
            >
              Reels
            </a>
            <a
              id="Headshots"
              className="mobile-menu-item"
              tabIndex="0"
              href="/headshots"
            >
              Headshots
            </a>
            <a
              id="Contact"
              className="mobile-menu-item"
              tabIndex="0"
              href="/contact"
            >
              Contact
            </a>
          </Menu>
        </div>
        <Router>
          <nav className="navbar">
            <div className="navbarBrand">
              <Link to="/">
                <p>Yelizaveta Sorkin</p>
              </Link>
            </div>
            <ul className="menuItems">
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/reels">Reels</Link>
              </li>
              <li>
                <Link to="/headshots">Headshots</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/reels" element={<Videos />} />
            <Route exact path="/headshots" element={<Headshots />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route path='*' element={<FourOhFour />} />
          </Routes>
        </Router>
      </div>
    );
	}
}

export default Layout;
